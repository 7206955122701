
const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/register", "/register/:referralID", "/login"],
    exact: true,
    component: "Auth",
  },
  {
    path: "/redeem",
    exact: true,
    component: "Redeem",
  },
  {
    path: "/vendor/:uid",
    exact: true,
    component: "OrderFlow",
  },
  {
    path: "/recipe/:recipeId",
    exact: true,
    component: "RecipeDetails",
  },
  {
    path: "/admin",
    exact: true,
    component: "Admin",
  },
  {
    path: "/privacy",
    exact: true,
    component: "Privacy",
  },
  {
    path: "/contact",
    exact: true,
    component: "Contact us",
  },
  {
    path: "/pricing",
    exact: true,
    component: "Pricing",
  },
  {
    path: "/edit/:username",
    exact: true,
    component: "Edit",
  },
// SEO PROGRAMMATIC 
  {
    path: "/continents",
    exact: true,
    component: "ContinentsList",
  },
  {
    path: "/continents/:continentName",
    exact: true,
    component: "ContinentDetail",
  },
  {
    path: "/continents/:continentName/countries",
    exact: true,
    component: "CountriesList",
  },
  {
    path: "/continents/:continentName/countries/:countryName",
    exact: true,
    component: "CountryDetail",
  },
  {
    path: "/continents/:continentName/countries/:countryName/states",
    exact: true,
    component: "StatesList",
  },
  {
    path: "/continents/:continentName/countries/:countryName/states/:stateName",
    exact: true,
    component: "StateDetail",
  },
  {
    path: "/farms/:stateName",
    exact: true,
    component: "StateDetail",
  },
  {
    path: "/farms/:stateName/:cityName",
    exact: true,
    component: "CityFarms",
  },
];

export default routes;